import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function PartnerModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`partner/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        // class: data.xxx,
        code: data.code,
        name: data.name,
        representative: data.representative,
        tel: data.tel,
        email: data.email,
        term: data.term,
        email_sales_1: data.email_sales_1,
        email_sales_2: data.email_sales_2,
        email_sales_3: data.email_sales_3,
        email_sales_4: data.email_sales_4,
        email_sales_5: data.email_sales_5,
        email_stock_1: data.email_stock_1,
        email_stock_2: data.email_stock_2,
        email_stock_3: data.email_stock_3,
        email_stock_4: data.email_stock_4,
        email_stock_5: data.email_stock_5,
        email_settle_1: data.email_settle_1,
        email_settle_2: data.email_settle_2,
        email_settle_3: data.email_settle_3,
        email_settle_4: data.email_settle_4,
        email_settle_5: data.email_settle_5,
        // notice_1: parseInt(data.notice_1, 10) === -1 ? '' : data.notice_1,
        // notice_2: parseInt(data.notice_2, 10) === -1 ? '' : data.notice_2,
        // notice_3: parseInt(data.notice_3, 10) === -1 ? '' : data.notice_3,
        // notice_4: parseInt(data.notice_4, 10) === -1 ? '' : data.notice_4,
        // notice_5: parseInt(data.notice_5, 10) === -1 ? '' : data.notice_5,
        entry_dt: moment(data.entry_dt).toDate()
      });
    }
  };

  // Yup.addMethod(Yup.string, 'unique', function () {
  //   // eslint-disable-next-line react/no-this-in-sfc
  //   return this.test('unique', '既に使用されています', async (value) => {
  //     if (value == null || value === '') return true;
  //     try {
  //       const {
  //         data: { result }
  //       } = await SystemLib.api(
  //         `user/check`,
  //         {
  //           id: targetId,
  //           user_id: value
  //         },
  //         'post'
  //       );
  //       return result;
  //     } catch (e) {
  //       return true;
  //     }
  //   });
  // });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const FormSchema = Yup.object().shape({
    name: Yup.string().required('法人名は必須です'),
    representative: Yup.string().required('代表者名は必須です'),
    tel: Yup.string().required('電話番号は必須です'),
    email: Yup.string()
      .email('メールアドレスの形式をご確認ください')
      .required('メールアドレスは必須です'),
    term: Yup.string().required('支払サイトは必須です'),
    email_sales_1: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_sales_2: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_sales_3: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_sales_4: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_sales_5: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_stock_1: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_stock_2: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_stock_3: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_stock_4: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_stock_5: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_settle_1: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_settle_2: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_settle_3: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_settle_4: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    email_settle_5: Yup.string().email('メールアドレスの形式をご確認ください').nullable(),
    // notice_1: yupNum,
    // notice_2: yupNum,
    // notice_3: yupNum,
    // notice_4: yupNum,
    // notice_5: yupNum,
    entry_dt: Yup.date().required('登録日は必須です')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      representative: '',
      tel: '',
      email: '',
      term: '',
      email_sales_1: '',
      email_sales_2: '',
      email_sales_3: '',
      email_sales_4: '',
      email_sales_5: '',
      email_stock_1: '',
      email_stock_2: '',
      email_stock_3: '',
      email_stock_4: '',
      email_stock_5: '',
      email_settle_1: '',
      email_settle_2: '',
      email_settle_3: '',
      email_settle_4: '',
      email_settle_5: '',
      // notice_1: '',
      // notice_2: '',
      // notice_3: '',
      // notice_4: '',
      // notice_5: '',
      entry_dt: moment().toDate()
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `partner${targetId === 0 ? '' : `/${targetId}`}`,
        {
          code: formik.values.code,
          name: formik.values.name,
          representative: formik.values.representative,
          tel: formik.values.tel,
          email: formik.values.email,
          term: formik.values.term,
          email_sales_1: formik.values.email_sales_1,
          email_sales_2: formik.values.email_sales_2,
          email_sales_3: formik.values.email_sales_3,
          email_sales_4: formik.values.email_sales_4,
          email_sales_5: formik.values.email_sales_5,
          email_stock_1: formik.values.email_stock_1,
          email_stock_2: formik.values.email_stock_2,
          email_stock_3: formik.values.email_stock_3,
          email_stock_4: formik.values.email_stock_4,
          email_stock_5: formik.values.email_stock_5,
          email_settle_1: formik.values.email_settle_1,
          email_settle_2: formik.values.email_settle_2,
          email_settle_3: formik.values.email_settle_3,
          email_settle_4: formik.values.email_settle_4,
          email_settle_5: formik.values.email_settle_5,
          // notice_1: formik.values.notice_1,
          // notice_2: formik.values.notice_2,
          // notice_3: formik.values.notice_3,
          // notice_4: formik.values.notice_4,
          // notice_5: formik.values.notice_5,
          entry_dt: moment(formik.values.entry_dt).format('YYYY-MM-DD')
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
              {/* <Stack direction="row" width="100%" mb={2}> */}
              <Stack direction="column" spacing={2} mt={1} mr={2} width="100%">
                {targetId > 0 && (
                  <TextField
                    id="code"
                    label="コード"
                    variant="outlined"
                    value={formik.values.code}
                    disabled
                  />
                )}
                <DesktopDatePicker
                  id="entry_dt"
                  label="登録日"
                  inputFormat="yyyy-MM-dd"
                  {...getFieldProps('endty_dt')}
                  value={formik.values.entry_dt}
                  onChange={(v) => {
                    formik.setFieldValue('entry_dt', moment(v).toDate());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={errors.entry_dt ? '登録日をご確認ください' : null}
                    />
                  )}
                />
                <TextField
                  id="name"
                  label="法人名"
                  variant="outlined"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <TextField
                  id="representative"
                  label="代表者名"
                  variant="outlined"
                  {...getFieldProps('representative')}
                  error={Boolean(touched.representative && errors.representative)}
                  helperText={touched.representative && errors.representative}
                  onChange={formik.handleChange}
                  value={formik.values.representative}
                />
                <TextField
                  id="tel"
                  label="電話番号"
                  variant="outlined"
                  {...getFieldProps('tel')}
                  error={Boolean(touched.tel && errors.tel)}
                  helperText={touched.tel && errors.tel}
                  onChange={formik.handleChange}
                  value={formik.values.tel}
                />
                <TextField
                  id="email"
                  label="メールアドレス"
                  variant="outlined"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <TextField
                  id="term"
                  label="支払サイト"
                  variant="outlined"
                  {...getFieldProps('term')}
                  error={Boolean(touched.term && errors.term)}
                  helperText={touched.term && errors.term}
                  onChange={formik.handleChange}
                  value={formik.values.term}
                />
                <Divider />
                {/* [1, 2, 3, 4, 5].map((v) => (
                  <TextField
                    id={`notice_${v}`}
                    key={`notice_${v}`}
                    label={`消費期限通知:日前(${v})`}
                    variant="outlined"
                    {...getFieldProps(`notice_${v}`)}
                    error={Boolean(touched[`notice_${v}`] && errors[`notice_${v}`])}
                    helperText={touched[`notice_${v}`] && errors[`notice_${v}`]}
                    onChange={formik.handleChange}
                    value={formik.values[`notice_${v}`]}
                  />
                )) */}
                {/* <Divider /> */}
                {[1, 2, 3, 4, 5].map((v) => (
                  <TextField
                    id={`email_sales_${v}`}
                    key={`email_sales_${v}`}
                    label={`売上連絡アドレス(${v})`}
                    variant="outlined"
                    {...getFieldProps(`email_sales_${v}`)}
                    error={Boolean(touched[`email_sales_${v}`] && errors[`email_sales_${v}`])}
                    helperText={touched[`email_sales_${v}`] && errors[`email_sales_${v}`]}
                    onChange={formik.handleChange}
                    value={formik.values[`email_sales_${v}`]}
                  />
                ))}
                {/*
                <Divider />
                {[1, 2, 3, 4, 5].map((v) => (
                  <TextField
                    id={`email_stock_${v}`}
                    key={`email_stock_${v}`}
                    label={`在荷連絡アドレス(${v})`}
                    variant="outlined"
                    {...getFieldProps(`email_stock_${v}`)}
                    error={Boolean(touched[`email_stock_${v}`] && errors[`email_stock_${v}`])}
                    helperText={touched[`email_stock_${v}`] && errors[`email_stock_${v}`]}
                    onChange={formik.handleChange}
                    value={formik.values[`email_stock_${v}`]}
                  />
                ))}
                */}
                <Divider />
                {[1, 2, 3, 4, 5].map((v) => (
                  <TextField
                    id={`email_settle_${v}`}
                    key={`email_settle_${v}`}
                    label={`決済連絡アドレス(${v})`}
                    variant="outlined"
                    {...getFieldProps(`email_settle_${v}`)}
                    error={Boolean(touched[`email_settle_${v}`] && errors[`email_settle_${v}`])}
                    helperText={touched[`email_settle_${v}`] && errors[`email_settle_${v}`]}
                    onChange={formik.handleChange}
                    value={formik.values[`email_settle_${v}`]}
                  />
                ))}
              </Stack>
              {/* </Stack> */}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
                sx={{ marginTop: 3 }}
              >
                {`${targetId === 0 ? '登録' : '更新'}する`}
              </LoadingButton>
            </Box>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
