import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const toolbarStyle_pe = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  // backgroundColor: 'rgba(96,169,91,0.3)',
  backgroundColor: 'rgba(211,255,255,1.0)',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const toolbarStyle_pn = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: 'rgba(255,247,205,1.0)',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { SystemLib } = window;
  const ToolbarStyle =
    SystemLib.userInfo.role === 'master' ||
    SystemLib.userInfo.role === 'admin' ||
    SystemLib.userInfo.role === 'staff'
      ? toolbarStyle_pe
      : toolbarStyle_pn;
  const textcolor =
    SystemLib.userInfo.role === 'master' ||
    SystemLib.userInfo.role === 'admin' ||
    SystemLib.userInfo.role === 'staff'
      ? '#006EB8'
      : '#EA5514';

  return (
    <RootStyle>
      <ToolbarStyle>
        {/* <MHidden width="lgUp"> */}
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: textcolor }}>
          <Icon icon={menu2Fill} />
        </IconButton>
        {/* </MHidden> */}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          {/* <AccountPopover /> */}
          {SystemLib.userInfo.partner_name && (
            <Typography color={textcolor}>{SystemLib.userInfo.partner_name}</Typography>
          )}
          {!SystemLib.userInfo.partner_name && (
            <Typography color={textcolor}>プランエディターズ</Typography>
          )}
          <Typography color={textcolor}>／</Typography>
          <Typography color={textcolor}>{SystemLib.userInfo.name}</Typography>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
