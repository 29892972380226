import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  Select,
  MenuItem
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function SaleModal({ open, onClose, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const valids = useRef({});
  const vals = useRef({});
  const [err, setErr] = useState('');

  const [device, setDevice] = useState('-1');
  const [devices, setDevices] = useState([]);

  const [dto, setDto] = useState(moment().toDate());
  const [dt, setDt] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    _getDevices();
  }, []);

  useEffect(() => {
    _valid();
  }, [dt]);

  useEffect(() => {
    setList([]);
    if (device !== '-1') {
      _getList();
    }
    valids.current = {};
    vals.current = {};
  }, [device]);

  const _getDevices = async () => {
    const { data } = await SystemLib.api('sale/devices', {}, 'post');
    if (data.result) {
      setDevices(data.data);
    }
  };

  const _getList = async () => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `sale/device`,
      {
        device_code: device
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList(data);
    }
  };

  const TABLE_HEAD = [
    { id: 'column_number', label: 'カラム番号', alignRight: false },
    { id: 'product_code', label: '商品コード', alignRight: false },
    { id: 'product_name', label: '商品名', alignRight: false },
    { id: 'stock', label: '在荷数', alignRight: false },
    { id: 'num', label: '販売数', alignRight: true }
  ];

  const _valid = () => {
    let ch = true;
    Object.keys(valids.current).forEach((key) => {
      if (!valids.current[key]) ch = false;
    });
    if (ch) {
      let sum = 0;
      Object.keys(vals.current).forEach((key) => {
        sum += Number(vals.current[key]);
      });
      if (sum === 0) {
        ch = false;
      }
    }
    if (dt === '') {
      ch = false;
    } else if (!moment(dt).isValid()) {
      ch = false;
    }
    setIsValid(ch);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
        <Modal
          open={open}
          onClose={() => {
            onClose();
          }}
        >
          <Box sx={styles.box} spacing={2} maxHeight="80%">
            <Scrollbar>
              <Stack direction="row" spacing={2} padding={1}>
                <DesktopDatePicker
                  id="dt"
                  label="売上日"
                  inputFormat="yyyy-MM-dd"
                  value={dto}
                  onChange={(v) => {
                    setDto(moment(v).toDate());
                    if (v === '' || v === null) {
                      setDt('');
                    } else {
                      setDt(moment(v).format('YYYY-MM-DD'));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField disablePast {...params} error={false} helperText={null} />
                  )}
                />
                <Select
                  labelId="select_label_devices"
                  id="select_devices"
                  value={device}
                  onChange={(e) => {
                    setDevice(e.target.value);
                  }}
                >
                  <MenuItem value="-1">デバイス 選択...</MenuItem>
                  {devices.map((v) => (
                    <MenuItem key={v.code} value={v.code}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CollectListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {list.map((row, index) => (
                      <ItemRow
                        key={index}
                        row={row}
                        onChange={(v) => {
                          vals.current[row.column_number] = v;
                          _valid();
                        }}
                        onValid={(v) => {
                          valids.current[row.column_number] = v;
                          _valid();
                        }}
                      />
                    ))}
                    {isLoading === false && list.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              デバイスを選択してください
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box textAlign="center" sx={{ marginTop: 3 }}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
                onClick={() => {
                  setConfOk(() => async () => {
                    setIsLoading(true);
                    const {
                      data: { result, data }
                    } = await SystemLib.api(
                      `sale/manual`,
                      {
                        dt,
                        device_code: device,
                        nums: vals.current
                      },
                      'post'
                    );
                    setIsLoading(false);
                    if (result) {
                      reload();
                      setDevice('-1');
                      setIsValid(false);
                      // onClose();
                    } else {
                      alert('登録に失敗しました');
                    }
                  });
                  setConf(true);
                }}
              >
                登録する
              </LoadingButton>
            </Box>
          </Box>
        </Modal>
        <ConfDialog
          open={conf}
          onOk={() => {
            confOk();
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
      </LocalizationProvider>
    </>
  );
}

function ItemRow({ row, onChange, onValid }) {
  const { id, device_code, column_number, product, product_code, stock_count } = row;
  const yupNum = Yup.number()
    .typeError('数字のみ')
    .integer('整数のみ')
    .min(0, '0以上')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    num: yupNum.max(stock_count < 0 ? 100000 : stock_count, '超過').required('必須')
  });

  const formik = useFormik({
    initialValues: {
      num: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {}
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  useEffect(() => {
    onChange(0);
  }, []);

  useEffect(() => {
    onValid(isValid);
  }, [isValid]);

  return (
    <TableRow hover key={id} tabIndex={-1}>
      <TableCell scope="row>">{column_number}</TableCell>
      <TableCell scope="row>">{product ? product.code : '未設定'}</TableCell>
      <TableCell scope="row>">{product ? product.name : '未設定'}</TableCell>
      <TableCell scope="row>">{stock_count < 0 ? '∞' : stock_count}</TableCell>
      <TableCell scope="row" padding="none" align="center" width={90}>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginLeft: 1 }}
          {...getFieldProps('num')}
          error={Boolean(touched.num && errors.num)}
          helperText={touched.num && errors.num}
          onChange={(v) => {
            onChange(v.target.value);
            formik.handleChange(v);
          }}
          value={formik.values.num}
          disabled={product === null}
        />
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    overflow: 'scroll'
  }
};
