import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/stock';
import CollectModal from './CollectModal';
import StorageModal from './StorageModal';
import ConfDialog from '../../components/ConfDialog';
import DeviceProduct from './DeviceProduct';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'device_code', label: 'デバイスコード', alignRight: false },
  { id: 'column_number', label: ['カラム', <br />, '番号'], alignRight: false },
  // { id: 'product_code', label: '商品コード', alignRight: false },
  { id: 'product_code', label: '商品名', alignRight: false },
  { id: 'stock_count', label: '実在荷', alignRight: false },
  { id: 'lot_order', label: '発注ロット', alignRight: false },
  // { id: 'pre_collect_count', label: ['回収', <br />, '指示'], alignRight: false },
  // { id: 'shortage_count', label: '不足', alignRight: false },
  // { id: 'pre_shipping_count', label: ['出荷', <br />, '指示'], alignRight: false },
  // { id: 'shipping_count', label: '出荷', alignRight: false },
  // { id: 'exp_status', label: '回収状況', alignRight: false },
  { id: 'dev_status', label: '販売状況', alignRight: false }, // { id: 'rep_status', label: '在荷状況', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function isNumeric(val) {
  return /^-?\d+$/.test(val);
}

function descendingComparator(a, b, orderBy) {
  if (!isNumeric(a[orderBy])) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy] * 1 < a[orderBy] * 1) {
      return -1;
    }
    if (b[orderBy] * 1 > a[orderBy] * 1) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, exp, rep) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.device_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (_target.product &&
          (_target.product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            _target.product.code.toLowerCase().indexOf(query.toLowerCase()) !== -1))
    );
  }
  if (exp !== -1) {
    array = filter(array, (_target) => _target.exp_status === exp);
  }
  if (rep !== -1) {
    array = filter(array, (_target) => _target.rep_status === rep);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Stock() {
  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [modalCollect, setModalCollect] = useState(false);
  const [modalStorage, setModalStorage] = useState(false);
  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [currentDeviceCode, setCurrentDeviceCode] = useState('');
  const [currentColumnNumber, setCurrentColumnNumber] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});

  const [openUserList, setOpenUserList] = useState(false);
  const [openProductList, setOpenProductList] = useState(false);
  // const [currentPartnerName, setCurrentPartnerName] = useState('');

  // フィルタ用
  const [exp, setExp] = useState(-1);
  const [rep, setRep] = useState(-1);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, exp, rep);

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('stock/list', {}, 'post');
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (device_code, column_number) => {
    const { data } = await SystemLib.api(
      `stock/item`,
      {
        device_code,
        column_number
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.device_code === device_code && v.column_number === column_number) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  // const deleteItems = async (ids) => {
  //   const tmp = chunk(ids, 1000);
  //   const p = [];
  //   tmp.forEach(async (arr) => {
  //     console.log(arr);
  //     p.push(
  //       SystemLib.api(
  //         `stock/delete`,
  //         {
  //           ids: arr
  //         },
  //         'post'
  //       )
  //     );
  //   });
  //   await Promise.all(p);
  //   const newItems = list.filter((v) => !ids.includes(v.id));
  //   setList(newItems);
  // };

  const reload = async (device_code, column_number) => {
    getItem(device_code, column_number);
  };

  const classes = useStyles();

  return (
    <>
      <Page title="デバイス管理">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              デバイス管理
            </Typography>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfAll(true);
              }}
              onChangeExp={(exp) => {
                setExp(exp);
              }}
              onChangeRep={(rep) => {
                setRep(rep);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          device_code,
                          column_number,
                          product,
                          product_code,
                          stock_count,
                          lot_order,
                          pre_collect_count,
                          shortage_count,
                          pre_shipping_count,
                          shipping_count,
                          exp_status,
                          rep_status,
                          dev_status
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        let exp_color = null;
                        if (exp_status.match(/注意/)) {
                          exp_color = '#fafad2';
                        } else if (exp_status.match(/必須/)) {
                          exp_color = '#fad9d2';
                        }
                        let rep_color = null;
                        if (rep_status.match(/検討/)) {
                          rep_color = '#fafad2';
                        } else if (rep_status.match(/要/)) {
                          rep_color = '#fad9d2';
                        }
                        let dev_color = null;
                        if (dev_status.match(/検討/)) {
                          dev_color = '#fafad2';
                        } else if (dev_status.match(/要/)) {
                          dev_color = '#fad9d2';
                        }
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell> */}
                            <TableCell scope="row" sx={{ paddingLeft: 3 }}>
                              {device_code}
                            </TableCell>
                            <TableCell scope="row" padding="none">
                              {column_number}
                            </TableCell>
                            {/* <TableCell scope="row" padding="none">
                              {product ? product.code : null}
                            </TableCell> */}
                            <TableCell scope="row" padding="none">
                              {[
                                product ? product.code : null,
                                <br />,
                                product ? product.name : null
                              ]}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="center">
                              {stock_count < 0 ? '∞' : stock_count}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="center">
                              {lot_order < 0 ? '∞' : lot_order}
                            </TableCell>
                            {/* <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                color: pre_collect_count > 0 ? '#00f' : null
                              }}
                            >
                              {pre_collect_count}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                color:
                                  rep_status !== '適正' && rep_status !== '未稼働' ? '#f00' : null
                              }}
                            >
                              {shortage_count}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                color: pre_shipping_count > 0 ? '#00f' : null
                              }}
                            >
                              {pre_shipping_count}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                color: shipping_count > 0 ? '#00f' : null
                              }}
                            >
                              {shipping_count}
                            </TableCell> */}
                            {/* <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                backgroundColor: exp_color
                              }}
                            >
                              {exp_status}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              sx={{ backgroundColor: rep_color }}
                              align="center"
                            >
                              {rep_status}
                            </TableCell> */}
                            <TableCell
                              scope="row"
                              padding="none"
                              sx={{ backgroundColor: dev_color }}
                              align="center"
                            >
                              {dev_status}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="right" width={250}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="info"
                                  onClick={() => {
                                    setUpdateDt(moment().unix());
                                    setCurrentDeviceCode(device_code);
                                    setCurrentColumnNumber(column_number);
                                    setOpenProductList(true);
                                  }}
                                  /* disabled={!(pre_shipping_count === 0 && shipping_count === 0)} */
                                >
                                  商品設定
                                </Button>
                                {/* <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    setUpdateDt(moment().unix());
                                    setCurrentDeviceCode(device_code);
                                    setCurrentColumnNumber(column_number);
                                    setModalCollect(true);
                                  }}
                                >
                                  回収
                                </Button> */}
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    setUpdateDt(moment().unix());
                                    setCurrentDeviceCode(device_code);
                                    setCurrentColumnNumber(column_number);
                                    setCurrentMax(shortage_count);
                                    setModalStorage(true);
                                  }}
                                >
                                  補充
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={11} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
          {openProductList && (
            <DeviceProduct
              open={openProductList}
              deviceCode={currentDeviceCode}
              columnNumber={currentColumnNumber}
              onClose={() => {
                setOpenProductList(false);
                setCurrentDeviceCode('');
                setCurrentColumnNumber(0);
                setUpdateDt(moment().unix());
              }}
              updateDt={updateDt}
              reload={(device_code, column_number) => {
                reload(device_code, column_number);
              }}
            />
          )}
        </Container>
        <CollectModal
          targetDeviceCode={currentDeviceCode}
          targetColumnNumber={currentColumnNumber}
          open={modalCollect}
          onClose={() => {
            setModalCollect(false);
          }}
          updateDt={updateDt}
          reload={(device_code, column_number) => {
            reload(device_code, column_number);
          }}
        />
        <StorageModal
          targetDeviceCode={currentDeviceCode}
          targetColumnNumber={currentColumnNumber}
          targetMax={currentMax}
          open={modalStorage}
          onClose={() => {
            setModalStorage(false);
          }}
          updateDt={updateDt}
          reload={(device_code, column_number) => {
            reload(device_code, column_number);
          }}
        />
        <ConfDialog
          open={conf}
          onOk={() => {
            confOk();
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            confOk();
            setSelected([]);
            setConfAll(false);
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
