import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Collapse,
  Alert,
  AlertTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------
const CommentStyle = styled('div')(({ theme }) => ({
  marginTop: 0,
  color: '#888'
}));

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { SystemLib } = window;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    SystemLib.removeSessionStorage('apiToken');
    SystemLib.apiToken = '';
    SystemLib.setUserInfo({
      name: ''
    });
  }, []);

  const LoginSchema = Yup.object().shape({
    userId: Yup.string().required('ログインIDは必須です'),
    password: Yup.string().required('パスワードは必須です')
  });

  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setOpen(false);
      const ret = await SystemLib.api('auth', {
        user_id: formik.values.userId,
        password: formik.values.password
      });
      if (!ret.data.api_token) {
        setOpen(true);
        return;
      }
      SystemLib.saveSessionStorage('apiToken', ret.data.api_token);
      SystemLib.apiToken = ret.data.api_token;
      SystemLib.setIsAuth(true);
      // SystemLib.saveSessionStorage('userInfo', ret.data.user_info);
      SystemLib.setUserInfo(ret.data.user_info);
      // console.log(ret.data.user_info);
      setOpen(false);
      navigate('/home', { replace: true });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>認証に失敗しました</AlertTitle>
          ログインID / パスワードをご確認ください
        </Alert>
      </Collapse>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="ログインID"
            {...getFieldProps('userId')}
            error={Boolean(touched.userId && errors.userId)}
            helperText={touched.userId && errors.userId}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.userId}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="パスワード"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          ログイン
        </LoadingButton>
      </Form>
      <CommentStyle style={{ marginTop: 30, color: '#888' }}>
        R2 事業再構築&emsp;機-3
        <br />
        ※事業再構築補助金事業以外での使用禁止
      </CommentStyle>
    </FormikProvider>
  );
}
