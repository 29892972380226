import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function IncentiveModal({
  targetId = 0,
  targetCode = '',
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`incentive/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        sales_s: data.sales_s,
        sales_e: data.sales_e,
        commission: data.commission
      });
    }
  };

  // Yup.addMethod(Yup.string, 'unique', function () {
  //   // eslint-disable-next-line react/no-this-in-sfc
  //   return this.test('unique', '既に使用されています', async (value) => {
  //     if (value == null || value === '') return true;
  //     try {
  //       const {
  //         data: { result }
  //       } = await SystemLib.api(
  //         `commission/check`,
  //         {
  //           id: targetId,
  //           code: value
  //         },
  //         'post'
  //       );
  //       return result;
  //     } catch (e) {
  //       return true;
  //     }
  //   });
  // });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const yupNumF = Yup.number()
    .typeError('数字を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const FormSchema = Yup.object().shape({
    sales_s: yupNum.required('売上金額(下限)は必須です'),
    sales_e: yupNum.required('売上金額(上限)は必須です'),
    commission: yupNumF.required('手数料率は必須です')
  });

  const formik = useFormik({
    initialValues: {
      sales_s: 0,
      sales_e: 0,
      commission: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `incentive${targetId === 0 ? '' : `/${targetId}`}`,
        {
          commission_code: targetCode,
          sales_s: formik.values.sales_s,
          sales_e: formik.values.sales_e,
          commission: formik.values.commission
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
              <Stack direction="column" spacing={2} mt={1} mr={2} width="100%">
                <TextField
                  id="sales_s"
                  label="売上金額(下限)"
                  variant="outlined"
                  {...getFieldProps('sales_s')}
                  error={Boolean(touched.sales_s && errors.sales_s)}
                  helperText={touched.sales_s && errors.sales_s}
                  onChange={formik.handleChange}
                  value={formik.values.sales_s}
                />
                <TextField
                  id="sales_e"
                  label="売上金額(上限)：上限を無限にするには「0」と入力してください"
                  variant="outlined"
                  {...getFieldProps('sales_e')}
                  error={Boolean(touched.sales_e && errors.sales_e)}
                  helperText={touched.sales_e && errors.sales_e}
                  onChange={formik.handleChange}
                  value={formik.values.sales_e}
                />
                <TextField
                  id="commission"
                  label="手数料率"
                  variant="outlined"
                  {...getFieldProps('commission')}
                  error={Boolean(touched.commission && errors.commission)}
                  helperText={touched.commission && errors.commission}
                  onChange={formik.handleChange}
                  value={formik.values.commission}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
                sx={{ marginTop: 3 }}
              >
                {`${targetId === 0 ? '登録' : '更新'}する`}
              </LoadingButton>
            </Box>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
