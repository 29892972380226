import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function OrderModal({ targetProductCode, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const valids = useRef({});
  const vals = useRef({});
  const [err, setErr] = useState('');

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetProductCode) {
      _getList(targetProductCode);
    }
    valids.current = {};
    vals.current = {};
  }, [targetProductCode, updateDt]);

  const _getList = async (targetProductCode) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `product/item`,
      {
        product_code: targetProductCode
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList([data]);
      vals.current[data.code] = data.order_num;
    }
  };

  const TABLE_HEAD = [
    { id: 'partner_name', label: ['パートナー'], alignRight: false },
    { id: 'code', label: ['商品'], alignRight: false },
    { id: 'num_in_devices', label: ['納入', <br />, 'ロット'], alignRight: false },
    { id: 'throughput', label: ['製造', <br />, '日数'], alignRight: false },
    { id: 'expiring', label: ['消費', <br />, '期限'], alignRight: false },
    { id: 'tekisei_stocks', label: ['適正', <br />, '在荷'], alignRight: false },
    // { id: 'device_stocks', label: ['在荷', <br />, '(Pod)'], alignRight: false },
    // { id: 'storage_stocks', label: ['在荷', <br />, '(保管)'], alignRight: false },
    { id: 'stock_sum', label: ['在荷', <br />, '(合計)'], alignRight: false },
    { id: 'stock_diff', label: ['適正', <br />, '差分'], alignRight: false },
    { id: 'order_quantity', label: ['仕入', <br />, '予定数'], alignRight: false },
    { id: 'lot_order', label: ['発注', <br />, 'ロット'], alignRight: false },
    { id: 'order_num', label: ['発注数'], alignRight: false }
  ];

  const _valid = () => {
    let ch = true;
    Object.keys(valids.current).forEach((key) => {
      if (!valids.current[key]) ch = false;
    });
    setIsValid(ch);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <Box sx={styles.box} spacing={2} maxHeight="80%">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CollectListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {list.map((row, index) => (
                    <ItemRow
                      key={index}
                      row={row}
                      onChange={(v) => {
                        vals.current[row.code] = v;
                        _valid();
                      }}
                      onValid={(v) => {
                        valids.current[row.code] = v;
                        _valid();
                      }}
                    />
                  ))}
                  {isLoading === false && list.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            在荷がありません
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            読み込み中
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box textAlign="center" sx={{ marginTop: 3 }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid}
              onClick={() => {
                console.log(vals.current);
                setConfOk(() => async () => {
                  setIsLoading(true);
                  const {
                    data: { result, data }
                  } = await SystemLib.api(
                    `product/order`,
                    {
                      product_code: targetProductCode,
                      quantity: vals.current[targetProductCode]
                    },
                    'post'
                  );
                  setIsLoading(false);
                  if (result) {
                    reload(targetProductCode);
                    onClose();
                  } else {
                    alert('登録に失敗しました');
                  }
                });
                setConf(true);
              }}
            >
              発注する
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row, onChange, onValid }) {
  const {
    partner_name,
    code,
    name,
    price,
    num_in_devices,
    throughput,
    expiring,
    tekisei_stocks,
    device_stocks,
    storage_stocks,
    stock_sum,
    stock_diff,
    order_quantity,
    order_num,
    lot_order
  } = row;

  const yupNum = Yup.number()
    .typeError('数字のみ')
    .integer('整数のみ')
    .min(1, '1以上')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    order_num: yupNum.required('必須')
  });

  const formik = useFormik({
    initialValues: {
      order_num
    },
    validationSchema: FormSchema,
    onSubmit: async () => {}
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  useEffect(() => {
    onValid(isValid);
  }, [isValid]);

  useEffect(() => {
    formik.setFieldValue('order_num', order_num);
  }, [row]);

  const numberWithComma = new Intl.NumberFormat();
  const priceFormat = numberWithComma.format(price);

  return (
    <TableRow hover key={code} tabIndex={-1}>
      <TableCell scope="row>">{partner_name}</TableCell>
      <TableCell scope="row>">
        {code}
        <br />
        {name}
        <br />¥{priceFormat}
      </TableCell>
      <TableCell scope="row>">{num_in_devices}</TableCell>
      <TableCell scope="row">中{throughput}日</TableCell>
      <TableCell scope="row">{expiring}日</TableCell>
      <TableCell scope="row">{tekisei_stocks}</TableCell>
      {/* <TableCell scope="row">{device_stocks}</TableCell>
      <TableCell scope="row">{storage_stocks}</TableCell> */}
      <TableCell scope="row">{stock_sum}</TableCell>
      <TableCell scope="row">{stock_diff}</TableCell>
      <TableCell scope="row">{order_quantity}</TableCell>
      <TableCell scope="row">{lot_order}</TableCell>
      <TableCell scope="row" padding="normal" align="center" width={120}>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginLeft: 1 }}
          {...getFieldProps('order_num')}
          error={Boolean(touched.order_num && errors.order_num)}
          helperText={touched.order_num && errors.order_num}
          onChange={(v) => {
            onChange(v.target.value);
            formik.handleChange(v);
          }}
          value={formik.values.order_num}
        />
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
