// material
import { Box, Grid, Hidden, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
// images
// import logoImage from '/static/TICpod_logo.png';

// ----------------------------------------------------------------------
const VerticalStyle = {
  alignItems: 'center'
};
const CommentStyle = styled('div')(({ theme }) => ({
  color: '#888'
}));

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="ホーム">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          {/* <Typography variant="h4">Hi, Welcome back</Typography> */}
          <Grid container spacing={3} style={VerticalStyle}>
            <Grid item xs={1} md={2} />
            <Grid item xs={11} md={3}>
              <img src={`${process.env.PUBLIC_URL}/static/TICpod_logo.png`} alt="ロゴ画像" />
            </Grid>
            <Hidden mdUp>
              <Grid item xs={1} />
            </Hidden>
            <Grid item xs={11} md={7}>
              <CommentStyle>
                R2 事業再構築&emsp;機-3
                <br />
                ※事業再構築補助金事業以外での使用禁止
              </CommentStyle>
            </Grid>
          </Grid>
        </Box>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid> */}
      </Container>
    </Page>
  );
}
