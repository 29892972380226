import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChangeStatusPe,
  onChangeStatusCl,
  onChangePartnerCode,
  onChangeY = null,
  onChangeM = null,
  onAllApprove = null
}) {
  const { SystemLib } = window;
  const [status_pe, setStatusPe] = useState(-1);
  const [status_cl, setStatusCl] = useState(-1);
  const statuses = ['承認済', '未承認'];
  const [partnerCode, setPartnerCode] = useState(-1);
  const [partners, setPartners] = useState([]);
  const [dto_y, setDtoY] = useState(moment().subtract(1, 'months').toDate());
  const [dto_m, setDtoM] = useState(moment().subtract(1, 'months').toDate());
  const [dt_y, setDtY] = useState(moment().subtract(1, 'months').format('YYYY'));
  const [dt_m, setDtM] = useState(moment().subtract(1, 'months').format('MM'));

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    const { data } = await SystemLib.api('layin/partners', {}, 'post');
    if (data.result) {
      setPartners(data.data);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} 件 選択中
          </Typography>
        ) : (
          <Stack direction="row" spacing={2}>
            <Select
              labelId="select_label_partner"
              id="select_partner"
              value={partnerCode}
              onChange={(e) => {
                setPartnerCode(e.target.value);
                onChangePartnerCode(e.target.value);
              }}
            >
              <MenuItem key={-1} value={-1}>
                パートナー選択...
              </MenuItem>
              {partners.map((v) => (
                <MenuItem key={v.code} value={v.code}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="select_label_status_pe"
              id="select_status_pe"
              value={status_pe}
              onChange={(e) => {
                setStatusPe(e.target.value);
                onChangeStatusPe(e.target.value);
              }}
            >
              <MenuItem key={-1} value={-1}>
                状況(PE) 選択...
              </MenuItem>
              {statuses.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
            <Select
              labelId="select_label_status_cl"
              id="select_status_cl"
              value={status_cl}
              onChange={(e) => {
                setStatusCl(e.target.value);
                onChangeStatusCl(e.target.value);
              }}
            >
              <MenuItem key={-1} value={-1}>
                状況(パートナー) 選択...
              </MenuItem>
              {statuses.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
            <DesktopDatePicker
              id="dt_y"
              label="対象年"
              views={['year']}
              inputFormat="yyyy"
              value={dto_y}
              onChange={(v) => {
                setDtoY(moment(v).toDate());
                if (v === '' || v === null) {
                  setDtY(-1);
                  setDtoY(null);
                  onChangeY(-1);
                } else {
                  setDtY(moment(v).format('YYYY'));
                  setDtoY(v);
                  onChangeY(moment(v).format('YYYY'));
                }
              }}
              renderInput={(params) => (
                <TextField disablePast {...params} error={false} helperText={null} />
              )}
            />
            <DesktopDatePicker
              id="dt_m"
              label="対象月"
              views={['month']}
              inputFormat="MM"
              value={dto_m}
              onChange={(v) => {
                setDtoM(moment(v).toDate());
                if (v === '' || v === null) {
                  setDtM(-1);
                  setDtoM(null);
                  onChangeM(-1);
                } else {
                  setDtM(moment(v).format('MM'));
                  setDtoM(v);
                  onChangeM(moment(v).format('MM'));
                }
              }}
              renderInput={(params) => (
                <TextField disablePast {...params} error={false} helperText={null} />
              )}
            />
          </Stack>
        )}
        {numSelected > 0 ? (
          <Tooltip title="一括承認">
            <Button
              size="small"
              type="button"
              variant="contained"
              disableElevation
              onClick={() => {
                onAllApprove();
              }}
            >
              承認する
            </Button>
          </Tooltip>
        ) : null}
      </RootStyle>
    </LocalizationProvider>
  );
}
