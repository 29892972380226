import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter, groupBy } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function PartnerUserModal({
  targetGroup = 0,
  targetId = 0,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getUser(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetGroup, targetId, updateDt]);

  const getUser = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`partner/user/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        partner_code: data.partner_code,
        user_id: data.user_id,
        name_l: data.name_l,
        name_f: data.name_f,
        role: data.role,
        position: data.position,
        password: '',
        email: data.email
      });
    }
  };

  Yup.addMethod(Yup.string, 'unique', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `partner/user/check`,
          {
            id: targetId,
            user_id: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  const FormSchema = Yup.object().shape({
    user_id: Yup.string().required('ユーザーIDは必須です').unique(),
    name_l: Yup.string().required('姓は必須です'),
    name_f: Yup.string().required('名は必須です'),
    role: Yup.string().required('役割は必須です'),
    position: Yup.string().required('役職は必須です'),
    password:
      targetId === 0
        ? Yup.string()
            .matches(
              /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/,
              '大文字を含む半角英数字を8文字以上入力してください'
            )
            .required('パスワードは必須です')
        : Yup.string().matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/,
            '大文字を含む半角英数字を8文字以上入力してください'
          ),
    email: Yup.string()
      .email('メールアドレスの形式をご確認ください')
      .required('メールアドレスは必須です')
  });

  const formik = useFormik({
    initialValues: {
      partner_code: targetGroup,
      user_id: '',
      name_l: '',
      name_f: '',
      role: 'partner_user',
      position: '',
      password: '',
      email: ''
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `partner/user${targetId === 0 ? '' : `/${targetId}`}`,
        {
          partner_code: targetId === 0 ? targetGroup : formik.values.partner_code,
          user_id: formik.values.user_id,
          name_l: formik.values.name_l,
          name_f: formik.values.name_f,
          role: formik.values.role,
          position: formik.values.position,
          password: formik.values.password,
          email: formik.values.email
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={styles.box} spacing={2}>
            <Scrollbar>
              <Stack direction="column" spacing={2} mt={1}>
                <TextField
                  id="user_id"
                  label="ユーザーID"
                  variant="outlined"
                  {...getFieldProps('user_id')}
                  error={Boolean(touched.user_id && errors.user_id)}
                  helperText={touched.user_id && errors.user_id}
                  onChange={formik.handleChange}
                  value={formik.values.user_id}
                />
                <TextField
                  id="password"
                  label={`パスワード${targetId > 0 ? '(変更する場合のみ)' : ''}`}
                  type="password"
                  variant="outlined"
                  {...getFieldProps('password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  autoComplete="new-password"
                />
                <TextField
                  id="position"
                  label="役職"
                  variant="outlined"
                  {...getFieldProps('position')}
                  error={Boolean(touched.position && errors.position)}
                  helperText={touched.position && errors.position}
                  onChange={formik.handleChange}
                  value={formik.values.position}
                />
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="name_l"
                    label="姓"
                    variant="outlined"
                    fullWidth
                    {...getFieldProps('name_l')}
                    error={Boolean(touched.name_l && errors.name_l)}
                    helperText={touched.name_l && errors.name_l}
                    onChange={formik.handleChange}
                    value={formik.values.name_l}
                  />
                  <TextField
                    id="name_f"
                    label="名"
                    variant="outlined"
                    fullWidth
                    {...getFieldProps('name_f')}
                    error={Boolean(touched.name_f && errors.name_f)}
                    helperText={touched.name_f && errors.name_f}
                    onChange={formik.handleChange}
                    value={formik.values.name_f}
                  />
                </Stack>
                <TextField
                  id="email"
                  label="メールアドレス"
                  variant="outlined"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormControl>
                  <FormLabel id="radio-buttons-group-label">役割</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    {...getFieldProps('role')}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    sx={{ pl: 2 }}
                  >
                    <FormControlLabel value="partner_admin" control={<Radio />} label="管理者" />
                    <FormControlLabel
                      value="partner_user"
                      control={<Radio />}
                      label="一般ユーザー"
                    />
                  </RadioGroup>
                </FormControl>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {`${targetId === 0 ? '登録' : '更新'}する`}
                </LoadingButton>
              </Stack>
            </Scrollbar>
          </Box>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
