import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem,
  TextField
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChangeStatus,
  onChangePartnerCode,
  onChangeDateS = null,
  onChangeDateE = null
}) {
  const { SystemLib } = window;
  const [status, setStatus] = useState(-1);
  const statuses = [
    { code: 'sale', name: '未承認' },
    { code: 'approved', name: '承認済' }
  ];
  const [partnerCode, setPartnerCode] = useState(-1);
  const [partners, setPartners] = useState([]);
  const [dto_s, setDtoS] = useState(moment().startOf('month'));
  const [dto_e, setDtoE] = useState(moment().endOf('month'));
  const [dt_s, setDtS] = useState(moment().format('YYYY-MM-01'));
  const [dt_e, setDtE] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    const { data } = await SystemLib.api('layin/partners', {}, 'post');
    if (data.result) {
      setPartners(data.data);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} 件 選択中
          </Typography>
        ) : (
          <Stack direction="row" spacing={2}>
            {/* <Select
              labelId="select_label_status"
              id="select_status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                onChangeStatus(e.target.value);
              }}
            >
              <MenuItem value={-1}>状況 選択...</MenuItem>
              {statuses.map((v) => (
                <MenuItem key={v.code} value={v.code}>
                  {v.name}
                </MenuItem>
              ))}
            </Select> */}
            <Select
              labelId="select_label_partner"
              id="select_partner"
              value={partnerCode}
              onChange={(e) => {
                setPartnerCode(e.target.value);
                onChangePartnerCode(e.target.value);
              }}
            >
              <MenuItem value={-1}>パートナー選択...</MenuItem>
              {partners.map((v) => (
                <MenuItem key={v.code} value={v.code}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
            <DesktopDatePicker
              id="dt_s"
              label="開始日"
              inputFormat="yyyy-MM-dd"
              value={dto_s}
              onChange={(v) => {
                setDtoS(moment(v).toDate());
                if (v === '' || v === null) {
                  setDtS(-1);
                  onChangeDateS(-1);
                } else {
                  setDtS(moment(v).format('YYYY-MM-DD'));
                  onChangeDateS(moment(v).format('YYYY-MM-DD'));
                }
              }}
              renderInput={(params) => (
                <TextField disablePast {...params} error={false} helperText={null} />
              )}
            />
            <DesktopDatePicker
              id="dt_e"
              label="終了日"
              inputFormat="yyyy-MM-dd"
              value={dto_e}
              onChange={(v) => {
                setDtoE(moment(v).toDate());
                if (v === '' || v === null) {
                  setDtE(-1);
                  onChangeDateE(-1);
                } else {
                  setDtE(moment(v).format('YYYY-MM-DD'));
                  onChangeDateE(moment(v).format('YYYY-MM-DD'));
                }
              }}
              renderInput={(params) => (
                <TextField disablePast {...params} error={false} helperText={null} />
              )}
            />
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder="商品検索..."
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </Stack>
        )}
      </RootStyle>
    </LocalizationProvider>
  );
}
