import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function CommissionModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`commission/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        // class: data.xxx,
        code: data.code,
        name: data.name,
        type: data.type,
        admission: data.admission,
        monthly: data.monthly,
        registration: data.registration,
        commission: data.commission
      });
    }
  };

  Yup.addMethod(Yup.string, 'unique', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `commission/check`,
          {
            id: targetId,
            code: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const yupNumF = Yup.number()
    .typeError('数字を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const FormSchema = Yup.object().shape({
    code: Yup.string().unique().required('型は必須です'),
    name: Yup.string().required('型名は必須です'),
    admission: yupNum.required('入会費は必須です'),
    monthly: yupNum.required('月額手数料は必須です'),
    registration: yupNum.required('商品登録設定費は必須です'),
    commission: yupNumF.required('手数料率(固定の場合)は必須です')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      type: 'incentive',
      admission: 0,
      monthly: 0,
      registration: 0,
      commission: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `commission${targetId === 0 ? '' : `/${targetId}`}`,
        {
          code: formik.values.code,
          name: formik.values.name,
          type: formik.values.type,
          admission: formik.values.admission,
          monthly: formik.values.monthly,
          registration: formik.values.registration,
          commission: formik.values.commission
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  const types = [
    { key: 'incentive', value: 'インセンティブ' },
    { key: 'fixed', value: '固定' }
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
              <Stack direction="column" spacing={2} mt={1} mr={2} width="100%">
                <TextField
                  id="code"
                  label="型"
                  variant="outlined"
                  {...getFieldProps('code')}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                  onChange={formik.handleChange}
                  value={formik.values.code}
                />
                <TextField
                  id="name"
                  label="型名"
                  variant="outlined"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <Select
                  labelId="select_label_type"
                  id="type"
                  {...getFieldProps('type')}
                  value={formik.values.type}
                  onChange={formik.handleChange}
                >
                  {types.map((v) => (
                    <MenuItem key={v.key} value={v.key}>
                      {v.value}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  id="admission"
                  label="入会費"
                  variant="outlined"
                  {...getFieldProps('admission')}
                  error={Boolean(touched.admission && errors.admission)}
                  helperText={touched.admission && errors.admission}
                  onChange={formik.handleChange}
                  value={formik.values.admission}
                />
                <TextField
                  id="monthly"
                  label="月額手数料"
                  variant="outlined"
                  {...getFieldProps('monthly')}
                  error={Boolean(touched.monthly && errors.monthly)}
                  helperText={touched.monthly && errors.monthly}
                  onChange={formik.handleChange}
                  value={formik.values.monthly}
                />
                <TextField
                  id="registration"
                  label="商品登録設定費"
                  variant="outlined"
                  {...getFieldProps('registration')}
                  error={Boolean(touched.registration && errors.registration)}
                  helperText={touched.registration && errors.registration}
                  onChange={formik.handleChange}
                  value={formik.values.registration}
                />
                <TextField
                  id="commission"
                  label="手数料率(固定の場合)"
                  variant="outlined"
                  {...getFieldProps('commission')}
                  error={Boolean(touched.commission && errors.commission)}
                  helperText={touched.commission && errors.commission}
                  onChange={formik.handleChange}
                  value={formik.values.commission}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
                sx={{ marginTop: 3 }}
              >
                {`${targetId === 0 ? '登録' : '更新'}する`}
              </LoadingButton>
            </Box>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
