import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function DeviceModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`device/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        // class: data.xxx,
        code: data.code,
        name: data.name,
        organizer: data.organizer,
        place_1: data.place_1,
        place_2: data.place_2,
        place_3: data.place_3,
        contract: data.contract,
        setup_dt: moment(data.setup_dt).toDate(),
        removal_dt: data.removal_dt ? moment(data.removal_dt).toDate() : null,
        columns: data.columns,
        items_per_column: data.items_per_column
        // caution_count: data.caution_count,
        // warning_count: data.warning_count
      });
    }
  };

  Yup.addMethod(Yup.string, 'unique', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `device/check`,
          {
            id: targetId,
            code: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    // code: Yup.string().unique().required('端末IDは必須です'),
    name: Yup.string().required('デバイス名は必須です'),
    organizer: Yup.string().required('デバイス設置事業体は必須です'),
    place_1: Yup.string().required('デバイス設置場所1は必須です'),
    contract: yupNum,
    setup_dt: Yup.date().required('設置日は必須です'),
    removal_dt: Yup.date().nullable().default(null),
    columns: yupNum.required('最大取扱商品数は必須です'), // ★★カラム数⇒最大取扱商品数
    items_per_column: yupNum.required('1商品の最大取扱数は必須です') // ★★最大充填数⇒1商品の最大取扱数
    // caution_count: yupNum.required('補充検討個数は必須です'),
    // warning_count: yupNum.required('要補充個数は必須です')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      organizer: '',
      place_1: '',
      place_2: '',
      place_3: '',
      contract: 0,
      setup_dt: moment().toDate(),
      removal_dt: null,
      columns: 8, // ★★7⇒8
      items_per_column: 10
      // caution_count: 8,
      // warning_count: 3
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `device${targetId === 0 ? '' : `/${targetId}`}`,
        {
          code: formik.values.code,
          name: formik.values.name,
          organizer: formik.values.organizer,
          place_1: formik.values.place_1,
          place_2: formik.values.place_2,
          place_3: formik.values.place_3,
          contract: formik.values.contract,
          setup_dt: moment(formik.values.setup_dt).format('YYYY-MM-DD'),
          removal_dt: formik.values.removal_dt
            ? moment(formik.values.removal_dt).format('YYYY-MM-DD')
            : '',
          columns: formik.values.columns,
          items_per_column: formik.values.items_per_column
          // caution_count: formik.values.caution_count,
          // warning_count: formik.values.warning_count
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
              <Stack direction="column" spacing={2} mt={0} mr={2} width="100%" mb={2}>
                {targetId > 0 && (
                  <TextField
                    id="code"
                    label="デバイスコード"
                    variant="outlined"
                    value={formik.values.code}
                    disabled
                  />
                )}
                <TextField
                  id="name"
                  label="デバイス名"
                  variant="outlined"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <TextField
                  id="organizer"
                  label="デバイス設置事業体"
                  variant="outlined"
                  {...getFieldProps('organizer')}
                  error={Boolean(touched.organizer && errors.organizer)}
                  helperText={touched.organizer && errors.organizer}
                  onChange={formik.handleChange}
                  value={formik.values.organizer}
                />
                <TextField
                  id="place_1"
                  label="デバイス設置場所1"
                  variant="outlined"
                  {...getFieldProps('place_1')}
                  error={Boolean(touched.place_1 && errors.place_1)}
                  helperText={touched.place_1 && errors.place_1}
                  onChange={formik.handleChange}
                  value={formik.values.place_1}
                />
                <TextField
                  id="place_2"
                  label="デバイス設置場所2"
                  variant="outlined"
                  {...getFieldProps('place_2')}
                  error={Boolean(touched.place_2 && errors.place_2)}
                  helperText={touched.place_2 && errors.place_2}
                  onChange={formik.handleChange}
                  value={formik.values.place_2}
                />
                <TextField
                  id="place_3"
                  label="デバイス設置場所3"
                  variant="outlined"
                  {...getFieldProps('place_3')}
                  error={Boolean(touched.place_3 && errors.place_3)}
                  helperText={touched.place_3 && errors.place_3}
                  onChange={formik.handleChange}
                  value={formik.values.place_3}
                />
                <TextField
                  id="contract"
                  label="契約料（／月）"
                  variant="outlined"
                  {...getFieldProps('contract')}
                  error={Boolean(touched.contract && errors.contract)}
                  helperText={touched.contract && errors.contract}
                  onChange={formik.handleChange}
                  value={formik.values.contract}
                />
                <DesktopDatePicker
                  id="setup_dt"
                  label="設置日"
                  inputFormat="yyyy-MM-dd"
                  {...getFieldProps('setup_dt')}
                  value={formik.values.setup_dt}
                  onChange={(v) => {
                    formik.setFieldValue('setup_dt', moment(v).toDate());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={errors.setup_dt ? '登録日をご確認ください' : null}
                    />
                  )}
                />
                <DesktopDatePicker
                  id="removal_dt"
                  label="撤去日"
                  inputFormat="yyyy-MM-dd"
                  {...getFieldProps('removal_dt')}
                  value={formik.values.removal_dt}
                  onChange={(v) => {
                    if (v) {
                      formik.setFieldValue('removal_dt', moment(v).toDate());
                    } else {
                      formik.setFieldValue('removal_dt', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={errors.removal_dt ? '撤去日をご確認ください' : null}
                    />
                  )}
                />
                <TextField
                  id="columns"
                  label="最大取扱商品数"
                  variant="outlined"
                  {...getFieldProps('columns')}
                  error={Boolean(touched.columns && errors.columns)}
                  helperText={touched.columns && errors.columns}
                  onChange={formik.handleChange}
                  value={formik.values.columns}
                />
                <TextField
                  id="items_per_column"
                  label="1商品の最大取扱数"
                  variant="outlined"
                  {...getFieldProps('items_per_column')}
                  error={Boolean(touched.items_per_column && errors.items_per_column)}
                  helperText={touched.items_per_column && errors.items_per_column}
                  onChange={formik.handleChange}
                  value={formik.values.items_per_column}
                />
                {/* <TextField
                  id="caution_count"
                  label="補充検討個数"
                  variant="outlined"
                  {...getFieldProps('caution_count')}
                  error={Boolean(touched.caution_count && errors.caution_count)}
                  helperText={touched.caution_count && errors.caution_count}
                  onChange={formik.handleChange}
                  value={formik.values.caution_count}
                />
                <TextField
                  id="warning_count"
                  label="要補充個数"
                  variant="outlined"
                  {...getFieldProps('warning_count')}
                  error={Boolean(touched.warning_count && errors.warning_count)}
                  helperText={touched.warning_count && errors.warning_count}
                  onChange={formik.handleChange}
                  value={formik.values.warning_count}
                /> */}
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
              >
                {`${targetId === 0 ? '登録' : '更新'}する`}
              </LoadingButton>
            </Box>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
