import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem,
  Button
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func,
  onChangeRole: PropTypes.func
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChangeRole,
  useMaster = true
}) {
  const [role, setRole] = useState('-1');

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} 件 選択中
        </Typography>
      ) : (
        <Stack direction="row" spacing={2}>
          {useMaster && (
            <Select
              labelId="select_label_exp"
              id="select_exp"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
                onChangeRole(e.target.value);
              }}
            >
              <MenuItem value={-1}>権限 選択...</MenuItem>
              <MenuItem key="master" value="master">
                マスター
              </MenuItem>
              <MenuItem key="admin" value="admin">
                管理者
              </MenuItem>
              {/*
              <MenuItem key="staff" value="staff">
                スタッフ
              </MenuItem>
              */}
            </Select>
          )}
          {!useMaster && (
            <Select
              labelId="select_label_exp"
              id="select_exp"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
                onChangeRole(e.target.value);
              }}
            >
              <MenuItem value={-1}>権限 選択...</MenuItem>
              <MenuItem key="partner_admin" value="partner_admin">
                管理者
              </MenuItem>
              <MenuItem key="partner_user" value="partner_user">
                一般ユーザー
              </MenuItem>
            </Select>
          )}
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="名前で検索..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="削除">
          <IconButton onClick={onDelete}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
